import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.getItem('authenticated') || false,
        admin: sessionStorage.getItem('admin') || false,
        userID: sessionStorage.getItem('userID') || null,
        userInitials: sessionStorage.getItem('userInitials') || null,
        userName: sessionStorage.getItem('userName') || null,
        user: {},        
        userRecord: null,
        studentRecord: null,
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        newPassword: '',
        displayPasswordChanged: false,
        validStudentID: false,
        step: 1,        
        passwordHint: '',
        passwordPlaceholder: '',          
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUserID (state) {
            return state.userID
        },      

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },

        getStudentRecord(state){
            return state.studentRecord
        },

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },

        getStudentIDValidation(state){
            return state.validStudentID
        },

        getStep(state){
            return state.step
        },
        
        getPasswordHint(state){
            return state.passwordHint
        },

        getPasswordPlaceholder(state){
            return state.passwordPlaceholder
        },           

        getAdmin(state){
            return state.admin
        },

        getUser (state) {
            return state.user
        },

        getUserInitials (state) {
            return state.userInitials
        },

        getUserName (state) {
            return state.userName
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUserID (state, value) {
            state.userID = value
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },

        setStudentRecord(state, value){
            state.studentRecord = value
        },

        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },

        setStudentIDValidation(state, value){
            state.validStudentID = value
        },

        setStep(state, value){
            state.step = value
        },

        setPasswordHint(state, value){
            state.passwordHint = value
        },

        setPasswordPlaceholder(state, value){
            state.passwordPlaceholder = value
        },

        setAdmin(state, value){
            state.admin = value
        },

        setUser (state, value) {
            state.user = value
        },

        setUserInitials (state, value) {
            state.userInitials = value
        },

        setUserName (state, value) {
            state.userName = value
        }
    },

    actions: {
        async signIn ({ commit }, credentials){                      
            commit('setOverlay', true); 
            try {
                await axios.get('/sanctum/csrf-cookie');
                const promiseLogin = axios.post('/api/login-student', credentials);                

                const { data } = await promiseLogin;
                
                commit('setAuthenticated', true);
                commit('setUserID', data.id);
                commit('setLoginErrors', '');
                commit('setUserInitials', data.first_name[0] + data.last_name[0]);
                commit('setUserName', data.first_name + ' ' + data.last_name)
                
                sessionStorage.authenticated = data.id;                
                sessionStorage.userID = data.id;
                sessionStorage.setItem('userInitials', data.first_name[0] + data.last_name[0]);
                sessionStorage.setItem('userName', data.first_name + ' ' + data.last_name)
                
            } catch (error) {                
                if(error.response && !error.response.data.errors){
                    console.log(error.response);
                    commit('setLoginErrors', error.response.data.message); 
                }
                else if(error.response && error.response.data.errors){
                    commit('setLoginErrors', error.response.data.errors.message[0])
                }                
                else console.log(error);

                commit('setAuthenticated', false);
                commit('setOverlay', false);  
            } 
        },

        async adminSignIn({ commit, dispatch }, credentials){
            commit('setOverlay', true);
            try{
                await axios.get('/sanctum/csrf-cookie');
                let response = await axios.post('/api/admin-login', credentials);
                console.log(response.status);
                sessionStorage.authenticated = response.data;
                sessionStorage.admin = response.data;
                commit('setLoginErrors', '')
                commit('setAuthenticated', true);
                commit('setUser', credentials);
                commit('setOverlay', false);
            }
            catch(error){
                dispatch('serverError', error)
                if(error.response) console.log(error.response);
                else console.log(error);
            }                  
        },

        async getStudent({ getters, commit }){
            //console.log(`UserId: ${getters.getUserID}`);  
            const url = '/api/student-record/' + getters.getUserID;
            
            try {
                const response = await axios.get(url);
                console.log(response);
                commit('setStudentRecord', response.data[0]);
                let userInitials = response.data[0].first_name.charAt(0)+response.data[0].last_name.charAt(0);
                let userName = response.data[0].first_name+' '+response.data[0].last_name;
                commit('setUser', {
                    student_id: response.data[0].id,
                    name: userName,
                    initials: userInitials
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
                            
        },       

        async changePassword({ getters, commit }){
            commit('setOverlay', true);
            console.log(`Password: ${getters.getNewPassword}`);
            console.log(`Student Id: ${getters.getUser.student_id}`);
            let url = '/api/change-password-student';
            return await axios.post(url, {
                "student_id" : getters.getUser.student_id,
                "password" : getters.getNewPassword,
            });                   
        },        

        async signOut({commit}){
            try {
                let response = await  axios.post('/logout');
                console.log(response.status);                
            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);                
            }
            commit('setAuthenticated', false); 
            sessionStorage.clear();  
        },
        
       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },

       async getStudents(){
           return await axios.get('/api/students')
       },

       async resetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-password-student',{
                "student_id" : getters.getUser.id,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

       serverError({ commit }, err ){
            console.log(err.response)
            var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'errors');                                     
            if( hasErrors && err.response.data.errors.message.length != 0){
                commit('setLoginErrors', err.response.data.errors.message[0]);
            }
            else{
                commit('setLoginErrors', err.response.statusText);
            }                    
            commit('setAuthenticated', false);
            commit('setOverlay', false);
       }
    }
}